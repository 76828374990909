import React, { Component } from 'react';

export default class Homepage extends Component {
    render() {
        return (
            <div style={{'padding-left': '4rem'}}>
                <div style={{'font-size': '5rem'}}>
                    Hello, <br /> I am Jion.
                </div>
                <div style={{'font-size':'1.5rem'}}>I want to write about stuff. </div>
            </div>

        )
    }
}